import React from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { Formik, FormikProps } from 'formik';
import { WeWashApiErrorTag } from '../../http/errors';
import ErrorFeedback from '../../forms/ErrorFeedback';
import {
    downloadGroupedSelectedUserInvoicesPdf,
    GroupedSelectedInvoiceRequestDto,
} from '../../store/grouped-user-invoices/groupedUserInvoicesAction';
import styles from './GroupedUserInvoice.module.css';
import { CommunicationState } from '../../store/communicationState';
import { downloadFile } from '../../util/downloadFileAsBlob';

interface Props {
    apiErrors: WeWashApiErrorTag[];
    loading: boolean;
    groupedSelectedInvoicesDownloadPdf: CommunicationState;
    downloadGroupedSelectedUserInvoicesPdf: (
        dto: GroupedSelectedInvoiceRequestDto,
        downloadAction: (filename: string, data: Blob) => void
    ) => void;
}

interface FormFields {
    invoiceNumbers: string;
}

function GroupedUserInvoices(props: Readonly<Props>) {
    const initialValues: FormFields = {
        invoiceNumbers: '',
    };

    const showPdfSpinner =
        props.groupedSelectedInvoicesDownloadPdf === CommunicationState.LOADING;

    return (
        <div
            id={'GetGroupedSelectedUserInvoices'}
            className={styles.groupedUserInvoices}
        >
            <Row className={styles.groupedUserInvoicesForm}>
                <h2>Nutzerrechnungen herunterladen</h2>
                <p>
                    Fügen Sie die Rechnungsnummer (`invoice_number`) der
                    gewünschten Rechnungen ein. Entweder mit Zeilenumbruch oder
                    Komma getrennt.
                </p>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                >
                    {renderForm}
                </Formik>
            </Row>
        </div>
    );

    function renderForm(formProps: FormikProps<FormFields>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <ErrorFeedback apiErrors={props.apiErrors} />
                <Row>
                    <Col>
                        <Form.Group controlId={'invoiceNumbers'}>
                            <Form.Label>Invoice Numbers</Form.Label>
                            <Form.Control
                                as={'textarea'}
                                rows={10}
                                name={'invoiceNumbers'}
                                value={formProps.values.invoiceNumbers}
                                onChange={formProps.handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <p>
                    <small>
                        *Nutzerrechnungen in Status ARCHIVED werden nicht
                        heruntergeladen. Sie sind nur aus dem Backup verfügbar.
                    </small>
                </p>
                <Button type={'submit'} disabled={showPdfSpinner}>
                    PDFs herunterladen
                    {showPdfSpinner && <Spinner animation={'border'} />}
                </Button>
            </Form>
        );
    }

    function handleFormSubmit(values: FormFields) {
        const listOfNumbers: string[] = values.invoiceNumbers
            .trim()
            .split(/[\s,]+/)
            .filter((it) => it);

        if (listOfNumbers.length > 0) {
            props.downloadGroupedSelectedUserInvoicesPdf(
                { invoice_numbers: listOfNumbers },
                downloadFile
            );
        }
    }
}

export default connect(
    (store: RootStore) => ({
        apiErrors: store.groupedUserInvoices.errors,
        loading: store.groupedUserInvoices.loading,
        groupedSelectedInvoicesDownloadPdf:
            store.groupedUserInvoices.groupedSelectedInvoicesPdfDownload,
    }),
    { downloadGroupedSelectedUserInvoicesPdf }
)(GroupedUserInvoices);
