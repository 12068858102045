import React, { FunctionComponent } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { RootStore } from '../../store/rootStore';
import { connect } from 'react-redux';
import {
    FailedPaymentRequestState,
    FinancialTransactionDto,
} from '../../store/failed-payment/failedPaymentReducer';
import { WeWashApiErrorTag } from '../../http/errors';
import {
    loadPaymentData,
    setPaymentFailed,
} from '../../store/failed-payment/failedPaymentActions';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';

function paymentFailedButton(props: Props) {
    if (props.loadingState === FailedPaymentRequestState.SUCCESS) {
        return (
            <Button
                disabled={hasPendingRequest(props)}
                onClick={() => {
                    if (props.data) {
                        props.setPaymentFailed(
                            props.data.financial_transaction_id
                        );
                    }
                }}
            >
                Payment Failed!
            </Button>
        );
    }
    return null;
}

function loadDetailsButton(props: Props) {
    return (
        <Button type="submit" disabled={hasPendingRequest(props)}>
            Load Details
        </Button>
    );
}

function hasPendingRequest(props: Props) {
    return (
        props.loadingState === FailedPaymentRequestState.LOADING ||
        props.setPaymentFailedState === FailedPaymentRequestState.LOADING
    );
}

function loadingStateDisplay(props: Props) {
    switch (props.loadingState) {
        case FailedPaymentRequestState.LOADING:
            return <div>...</div>;
        case FailedPaymentRequestState.FAILURE:
            return (
                <div>
                    <strong>Load Details failed</strong>, {props.loadingErrors}
                </div>
            );
        default:
            return <span />;
    }
}

function setPaymentFailedStateDisplay(props: Props) {
    switch (props.setPaymentFailedState) {
        case FailedPaymentRequestState.LOADING:
            return <div>...</div>;
        case FailedPaymentRequestState.FAILURE:
            return (
                <div>
                    <strong>Could not fail the payment</strong>, Reason:{' '}
                    {props.setPaymentFailedErrors}
                </div>
            );
        case FailedPaymentRequestState.SUCCESS:
            return <div>Successfully set the payment to failed</div>;
        default:
            return <span />;
    }
}

function paymentDetailsTable(props: Props) {
    if (
        props.data &&
        props.loadingState === FailedPaymentRequestState.SUCCESS
    ) {
        return (
            <table>
                <tr>
                    <td>User ID:</td>
                    <td>{props.data.user_id}</td>
                </tr>
                <tr>
                    <td>Name:</td>
                    <td>{props.data.user_name}</td>
                </tr>
                <tr>
                    <td>Payment Information:</td>
                    <td>{props.data.payment_method_info}</td>
                </tr>
                <tr>
                    <td>Type:</td>
                    <td>{props.data.financial_document_type}</td>
                </tr>
                <tr>
                    <td>Date:</td>
                    <td>
                        {moment(props.data.timestamp).format(
                            'MMMM Do YYYY, HH:mm:ss'
                        )}
                    </td>
                </tr>
                <tr>
                    <td>Payment Status:</td>
                    <td>{props.data.financial_document_payment_status}</td>
                </tr>
                <tr>
                    <td>Payment Method ID:</td>
                    <td>{props.data.payment_method_id}</td>
                </tr>
                <tr>
                    <td>Payment Method Status:</td>
                    <td>{props.data.payment_method_status}</td>
                </tr>
            </table>
        );
    }
    return null;
}

interface Props {
    loadPaymentData: (financialDocumentNumber: string) => any;
    setPaymentFailed: (financialTransactionId: number) => any;

    loadingState: FailedPaymentRequestState;
    setPaymentFailedState: FailedPaymentRequestState;
    data: FinancialTransactionDto | null;
    loadingErrors: WeWashApiErrorTag[];
    setPaymentFailedErrors: WeWashApiErrorTag[];
}

interface SearchParameters {
    financialDocumentNumber: string;
}

const schema: ObjectSchema<SearchParameters> = yup.object({
    financialDocumentNumber: yup.string(),
});

const FailedPaymentForm: FunctionComponent<Props> = (props) => {
    const searchParameters: SearchParameters = {
        financialDocumentNumber: props.data
            ? props.data.financial_document_number
            : '',
    };

    function handleFormSubmit(values: SearchParameters) {
        props.loadPaymentData(values.financialDocumentNumber);
    }

    return (
        <div id="FailedPaymentForm">
            <Formik
                validationSchema={schema}
                enableReinitialize
                initialValues={searchParameters}
                onSubmit={handleFormSubmit}
            >
                {(formProps: FormikProps<SearchParameters>) => {
                    return (
                        <Form noValidate onSubmit={formProps.handleSubmit}>
                            <Form.Group controlId="financialDocumentNumber">
                                <Form.Label>
                                    Invoice Number / Rechnungsnr.
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={formProps.handleChange}
                                    value={
                                        formProps.values.financialDocumentNumber
                                    }
                                />
                            </Form.Group>
                            {loadDetailsButton(props)}
                        </Form>
                    );
                }}
            </Formik>
            {loadingStateDisplay(props)}
            {paymentDetailsTable(props)}
            {paymentFailedButton(props)}
            {setPaymentFailedStateDisplay(props)}
        </div>
    );
};

export default connect(
    (store: RootStore) => ({
        ...store.failedPayment,
    }),
    { loadPaymentData, setPaymentFailed }
)(FailedPaymentForm);
