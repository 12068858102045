import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { goTo } from '../store/router/routerActions';
import { Route } from '../Router';
import { RootStore } from '../store/rootStore';
import Nav from 'react-bootstrap/Nav';
import classnames from 'classnames';
import styles from './App.module.css';
import { logoutAction } from '../store/user/userActions';
import WhiteLogo from './WeWash_Logo_white.png';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

enum Topic {
    NONE = 'NONE',
    REGISTRATION = 'REGISTRIERUNG',
    RACCOON = 'RACCOON',
    SEARCH = 'BENUTZERSUCHE',
    PAYMENT = 'ZAHLUNG',
    REQUEST = 'ANFRAGE',
    MANAGE_APPLIANCE = 'MASCHINEN',
    MANAGE_LAUNDRY_ROOM = 'WASCHRAUM',
    PARTNER = 'PARTNER',
    MANSION = 'OBJEKT',
    ABRECHNUNG = 'ABRECHNUNG',
    RECHNUNGSSTORNIERUNG = 'RECHNUNGSSTORNIERUNG'
}

interface Routing {
    route: Route;
    title: string;
    topic: Topic;
    admin: boolean;
}

interface Props {
    goTo: (route: Route) => void;
    userIsAdmin: boolean;
    logoutAction: () => {};
    loggedIn: boolean;
    currentRoute: Route;
}

const Menu: FunctionComponent<Props> = (props) => {
    const [openTopic, setOpenTopic] = useState<Topic>(Topic.NONE);
    const menuMapping: Routing[] = [
        {
            route: Route.REGISTRATION_FORM,
            title: 'REGISTRIERUNG',
            topic: Topic.REGISTRATION,
            admin: false,
        },
        {
            route: Route.RACCOON,
            title: 'RACCOON',
            topic: Topic.RACCOON,
            admin: true,
        },
        {
            route: Route.SEARCH_USER,
            title: 'BENUTZERSUCHE MIT EMAIL,CUSTOMER ID ODER TELEFONNUMMER',
            topic: Topic.SEARCH,
            admin: false,
        },
        {
            route: Route.SEARCH_USER_ID,
            title: 'BENUTZERSUCHE MIT ID',
            topic: Topic.SEARCH,
            admin: true,
        },
        {
            route: Route.FAVORITE_USERS_BY_ID,
            title: 'FAVORITEN',
            topic: Topic.SEARCH,
            admin: true,
        },
        {
            route: Route.BLACKLIST_IBAN,
            title: 'IBAN - Schwarze Liste',
            topic: Topic.PAYMENT,
            admin: true,
        },
        {
            route: Route.FAILED_PAYMENT,
            title: 'Gescheiterte Zahlung',
            topic: Topic.PAYMENT,
            admin: true,
        },
        {
            route: Route.TOP_UP,
            title: 'Konto aufladen',
            topic: Topic.PAYMENT,
            admin: true,
        },
        {
            route: Route.REFUND_RESERVATION,
            title: 'Buchung Rückerstatten',
            topic: Topic.REQUEST,
            admin: false,
        },
        {
            route: Route.CANCEL_USER_INVOICE,
            title: 'Rechnung Rückerstatten',
            topic: Topic.REQUEST,
            admin: false,
        },
        {
            route: Route.SEARCH_LAUNDRY_ROOM_BY_ID,
            title: 'Waschraumsuche',
            topic: Topic.MANAGE_LAUNDRY_ROOM,
            admin: true,
        },
        {
            route: Route.MANAGE_LAUNDRY_ROOM_CARDS,
            title: 'Waschraum Karte',
            topic: Topic.MANAGE_LAUNDRY_ROOM,
            admin: true,
        },
        {
            route: Route.MANAGE_LAUNDRY_ROOM_CONNECTIVITY,
            title: 'Waschraum Konnektivität',
            topic: Topic.MANAGE_LAUNDRY_ROOM,
            admin: true,
        },
        {
            route: Route.MANAGE_APPLIANCE,
            title: 'Maschinen',
            topic: Topic.MANAGE_LAUNDRY_ROOM,
            admin: true,
        },
        {
            route: Route.MANAGE_PARTNER,
            title: 'Partner verwalten',
            topic: Topic.PARTNER,
            admin: true,
        },
        {
            route: Route.MANAGE_HUB,
            title: 'Hub verwalten',
            topic: Topic.PARTNER,
            admin: true,
        },
        {
            route: Route.MANAGE_B2B_USER,
            title: 'B2B Benutzer',
            topic: Topic.PARTNER,
            admin: true,
        },
        {
            route: Route.MANAGE_VIP_USERS,
            title: 'VIP Nutzer',
            topic: Topic.PARTNER,
            admin: false,
        },
        {
            route: Route.SEARCH_MANSION,
            title: 'Objektsuche mit ID',
            topic: Topic.MANSION,
            admin: true,
        },
        {
            route: Route.GET_USER_INVOICES_ZIP,
            title: 'Nutzerechnungen',
            topic: Topic.ABRECHNUNG,
            admin: true,
        },
        {
            route: Route.GET_USER_SELECTED_INVOICES_ZIP,
            title: 'Nutzerechnungen (Auswahl)',
            topic: Topic.ABRECHNUNG,
            admin: true,
        },
        {
            route: Route.SEARCH_PARTNER_CREDIT_NOTES,
            title: 'Gutschriften (einzeln)',
            topic: Topic.ABRECHNUNG,
            admin: true,
        },
        {
            route: Route.GET_CREDIT_NOTES_ZIP,
            title: 'Gutschriften (batch)',
            topic: Topic.ABRECHNUNG,
            admin: true,
        },
    ];
    const routesToRender = props.userIsAdmin
        ? menuMapping
        : menuMapping.filter((it) => !it.admin);

    return (
        <div id="Menu">
            <Nav
                defaultActiveKey={props.currentRoute}
                className={classnames('flex-column', styles.navbar)}
            >
                <Nav.Link
                    className={styles.navLogo}
                    onClick={() => props.goTo(Route.SEARCH_USER)}
                >
                    <img alt="White WeWash Logo" src={WhiteLogo} />
                </Nav.Link>
                <Nav.Link
                    className={styles.navLink}
                    onClick={() => props.goTo(Route.REGISTRATION_FORM)}
                >
                    {Topic.REGISTRATION}
                </Nav.Link>
                <Nav.Link
                    className={styles.navLink}
                    onClick={() =>
                        window.open(
                            process.env.REACT_APP_RACCOON_URL || '',
                            '_blank'
                        )
                    }
                >
                    {Topic.RACCOON}
                </Nav.Link>
                {renderMenuItem(Topic.SEARCH)}
                {renderMenuItem(Topic.PAYMENT)}
                {renderMenuItem(Topic.REQUEST)}
                {renderMenuItem(Topic.MANAGE_APPLIANCE)}
                {renderMenuItem(Topic.MANAGE_LAUNDRY_ROOM)}
                {renderMenuItem(Topic.PARTNER)}
                {renderMenuItem(Topic.MANSION)}
                {renderMenuItem(Topic.ABRECHNUNG)}
                <Nav.Link
                    className={classnames(styles.bottom, styles.navLink)}
                    onClick={props.logoutAction}
                >
                    <span>AUSLOGGEN</span>&nbsp;
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={faSignOutAlt}
                    />
                </Nav.Link>
            </Nav>
        </div>
    );

    function renderMenuItem(topic: Topic) {
        const topicRoutes = routesToRender.filter((it) => it.topic === topic);
        if (topicRoutes.length < 1) {
            return;
        }
        return (
            <>
                <Nav.Item
                    onClick={handleOpenTopic}
                    className={classnames('pl-3 py-2', styles.navLink)}
                    aria-controls="search-collapse-text"
                    aria-expanded={openTopic === topic}
                >
                    {topic}
                    <FontAwesomeIcon className={styles.icon} icon={faPlus} />
                </Nav.Item>
                <Collapse in={openTopic === topic}>
                    <div id="search-collapse-text">
                        {topicRoutes.map((it) => (
                            <Nav.Link
                                key={it.route}
                                eventKey={it.route}
                                className={classnames(
                                    'pl-4',
                                    styles.secondLevel,
                                    styles.navLink
                                )}
                                id={it.route.toLowerCase()}
                                onClick={() => props.goTo(it.route)}
                            >
                                {it.title.toUpperCase()}
                            </Nav.Link>
                        ))}
                    </div>
                </Collapse>
            </>
        );

        function handleOpenTopic() {
            if (openTopic === topic) {
                setOpenTopic(Topic.NONE);
            } else {
                setOpenTopic(topic);
            }
        }
    }
};

export default connect(
    (store: RootStore) => ({
        userIsAdmin: store.user.userIsAdmin,
        loggedIn: store.user.loggedIn,
        currentRoute: store.router.currentRoute,
    }),
    { goTo, logoutAction }
)(Menu);
