import { createReducer } from 'redux-starter-kit';
import { WeWashApiErrorTag } from '../../http/errors';
import {
    CHANGE_ALLOW_PLAIN_SEPA_MANDATE,
    CHANGE_ALLOW_PLAIN_SEPA_MANDATE_FAILURE,
    CHANGE_ALLOW_PLAIN_SEPA_MANDATE_SUCCESS,
    CHANGE_INVOICE_DELIVERY,
    CHANGE_INVOICE_DELIVERY_FAILURE,
    CHANGE_INVOICE_DELIVERY_SUCCESS,
    CHANGE_LAUNDRY_ROOM_METHOD,
    CHANGE_LAUNDRY_ROOM_METHOD_FAILURE,
    CHANGE_LAUNDRY_ROOM_METHOD_SUCCESS,
    CHANGE_USER_PARALLEL_RESERVATIONS,
    CHANGE_USER_PARALLEL_RESERVATIONS_FAILURE,
    CHANGE_USER_PARALLEL_RESERVATIONS_SUCCESS,
    DELETE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD_FAILURE,
    DELETE_PAYMENT_METHOD_SUCCESS,
    DELETE_USER,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    LOAD_USER_DETAILS,
    LOAD_USER_DETAILS_FAILURE,
    LOAD_USER_DETAILS_SUCCESS,
    REVALIDATE_PAYMENT_METHOD,
    REVALIDATE_PAYMENT_METHOD_FAILURE,
    REVALIDATE_PAYMENT_METHOD_SUCCESS,
    SELECT_PAYMENT_METHOD,
    SELECT_PAYMENT_METHOD_FAILURE,
    SELECT_PAYMENT_METHOD_SUCCESS,
    USER_ACTION_FAILURE,
    USER_ACTION_RESET,
    USER_ACTION_STARTED,
    USER_ACTION_SUCCESS,
} from './manageUserActions';
import { RESET_VIEWS } from '../genericActions';
import { AmountAndCurrency } from '../amountAndCurrency';
import { CommunicationState } from '../communicationState';
import { InvoiceItemStatusForRefund } from '../refund-reservation/refundReservationReducer';
import { InvoiceBillingType } from '../pay-invoice/payInvoiceReducer';

interface ApplianceStats {
    installed_washers: number;
    enabled_washers: number;
    installed_dryers: number;
    enabled_dryers: number;
}

export enum PaymentMethodStatus {
    VERIFIED = 'VERIFIED',
    TRIAL = 'TRIAL',
    UNVERIFIED = 'UNVERIFIED',
    EXPIRED = 'EXPIRED',
    INVALID = 'INVALID',
    NEW = 'NEW',
    EXPIRED_TRIAL = 'EXPIRED_TRIAL',
    TRUSTED = 'TRUSTED',
}

export enum InvoicePaymentStatus {
    NEW = 'NEW',
    OPEN = 'OPEN',
    WAITING_FOR_EXTERNAL_CHECKOUT = 'WAITING_FOR_EXTERNAL_CHECKOUT',
    PROCESSING = 'PROCESSING',
    PAID = 'PAID',
    FAILED = 'FAILED',
}

export enum PaymentMethodCumulativeType {
    CUMULATIVE_PAYMENT_NOT_AVAILABLE = 'CUMULATIVE_PAYMENT_NOT_AVAILABLE',
    CUMULATIVE_PAYMENT_ACCEPTED = 'CUMULATIVE_PAYMENT_ACCEPTED',
    CUMULATIVE_PAYMENT_NOT_ACCEPTED = 'CUMULATIVE_PAYMENT_NOT_ACCEPTED',
}

export interface PaymentMethod {
    id: number;
    selected: boolean;
    payment_info: string;
    type: PaymentMethodType;
    valid_until: string;
    status: PaymentMethodStatus;
    failure_rate_percent: number;
    valid_for_selection: boolean;
    cumulative_type: PaymentMethodCumulativeType;
}

export enum TransactionStatus {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    NEW = 'NEW',
    OPEN_REFUND = 'OPEN_REFUND',
    REFUNDED = 'REFUNDED',
    FAILED_REFUND = 'FAILED_REFUND',
    PAYMENT_SETTLED = 'PAYMENT_SETTLED',
    REFUND_SETTLED = 'REFUND_SETTLED',
}

export enum PaymentMethodType {
    checkout = 'checkout',
    debit = 'debit',
    creditcard = 'creditcard',
    paypal = 'paypal',
}

export enum CcReservationActions {
    REFUND_INVOICE_ITEM = 'REFUND_INVOICE_ITEM',
    UNLOCK_APPLIANCE = 'UNLOCK_APPLIANCE',
}

export type ReservationBookingLogic =
    | 'APPLIANCE_START'
    | 'USER_DIRECT_BOOKING'
    | 'PAY2WASH'
    | 'PAY2WASH_ANONYMOUS'
    | 'PAY2WASH_VOUCHER';

export interface Reservation {
    reservation_id: number;
    booking_time?: number;
    queue_time?: number;
    reservation_status: string;
    lifecycle_state: string;
    short_name?: string;
    appliance_id?: number;
    invoice_item_status?: InvoiceItemStatusForRefund;
    available_actions: CcReservationActions[];
    booking_logic: ReservationBookingLogic;
}

export interface FinancialTransaction {
    type: string;
    transaction_date: number;
    invoice_or_debt_no: string;
    amount: AmountAndCurrency;
    payment_information: string;
    transaction_status: TransactionStatus;
    country_code: string;
}

interface OutstandingInvoice {
    invoice_id: number;
    creation_time: number;
    update_time: number;
    invoice_number: string;
    country_code: string;
    amount: AmountAndCurrency;
    payment_status: InvoicePaymentStatus;
    manual_payment_allowed: boolean;
    target_accounting_state: string;
    billing_type?: InvoiceBillingType;
}

export interface UserExtendedDetails {
    user_id: number;
    appliance_stats: ApplianceStats;
    payment_methods: PaymentMethod[];
    checkout_payment_method?: PaymentMethod;
    last_ten_financial_transactions: FinancialTransaction[];
    last_five_reservations: Reservation[];
    outstanding_invoices: OutstandingInvoice[];
}

export interface ManageUserStore {
    details: UserExtendedDetails | null;
    loading: boolean;
    errors: WeWashApiErrorTag[];
    deleteUserErrors: WeWashApiErrorTag[];
    patching: boolean;
    patchErrors: WeWashApiErrorTag[];
    userActionStatus: CommunicationState;
    userActionErrors: WeWashApiErrorTag[];
}

const initialStore: ManageUserStore = {
    details: null,
    loading: false,
    errors: [],
    deleteUserErrors: [],
    patching: false,
    patchErrors: [],
    userActionStatus: CommunicationState.INITIAL,
    userActionErrors: [],
};

export const manageUserReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [LOAD_USER_DETAILS]: (state) => {
        state.loading = true;
        state.errors = [];
    },
    [LOAD_USER_DETAILS_SUCCESS]: (state, action) => {
        state.details = action.payload.data;
        state.loading = false;
        state.errors = [];
    },
    [LOAD_USER_DETAILS_FAILURE]: (state, action) => {
        state.details = null;
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [SELECT_PAYMENT_METHOD]: (state) => {
        state.loading = true;
        state.errors = [];
    },
    [SELECT_PAYMENT_METHOD_SUCCESS]: (state) => {
        state.loading = false;
        state.errors = [];
    },
    [SELECT_PAYMENT_METHOD_FAILURE]: (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [DELETE_PAYMENT_METHOD]: (state) => {
        state.loading = true;
        state.errors = [];
    },
    [DELETE_PAYMENT_METHOD_SUCCESS]: (state) => {
        state.loading = false;
        state.errors = [];
    },
    [DELETE_PAYMENT_METHOD_FAILURE]: (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [DELETE_USER]: (state) => {
        state.loading = true;
        state.deleteUserErrors = [];
    },
    [DELETE_USER_SUCCESS]: (state) => {
        state.loading = false;
        state.deleteUserErrors = [];
        state.details = initialStore.details;
    },
    [DELETE_USER_FAILURE]: (state, action) => {
        state.loading = false;
        state.deleteUserErrors = action.payload.errors;
    },
    [REVALIDATE_PAYMENT_METHOD]: (state) => {
        state.loading = true;
        state.errors = [];
    },
    [REVALIDATE_PAYMENT_METHOD_SUCCESS]: (state) => {
        state.loading = false;
        state.errors = [];
    },
    [REVALIDATE_PAYMENT_METHOD_FAILURE]: (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [CHANGE_LAUNDRY_ROOM_METHOD]: (state) => {
        state.patching = true;
        state.patchErrors = [];
    },
    [CHANGE_LAUNDRY_ROOM_METHOD_SUCCESS]: (state) => {
        state.patching = false;
        state.patchErrors = [];
    },
    [CHANGE_LAUNDRY_ROOM_METHOD_FAILURE]: (state, action) => {
        state.patching = false;
        state.patchErrors = action.payload.errors;
    },
    [CHANGE_ALLOW_PLAIN_SEPA_MANDATE]: (state) => {
        state.patching = true;
        state.patchErrors = [];
    },
    [CHANGE_ALLOW_PLAIN_SEPA_MANDATE_SUCCESS]: (state) => {
        state.patching = false;
        state.patchErrors = [];
    },
    [CHANGE_ALLOW_PLAIN_SEPA_MANDATE_FAILURE]: (state, action) => {
        state.patching = false;
        state.patchErrors = action.payload.errors;
    },
    [CHANGE_USER_PARALLEL_RESERVATIONS]: (state) => {
        state.patching = true;
        state.patchErrors = [];
    },
    [CHANGE_USER_PARALLEL_RESERVATIONS_SUCCESS]: (state) => {
        state.patching = false;
        state.patchErrors = [];
    },
    [CHANGE_USER_PARALLEL_RESERVATIONS_FAILURE]: (state, action) => {
        state.patching = false;
        state.patchErrors = action.payload.errors;
    },
    [CHANGE_INVOICE_DELIVERY]: (state) => {
        state.patching = true;
        state.patchErrors = [];
    },
    [CHANGE_INVOICE_DELIVERY_SUCCESS]: (state) => {
        state.patching = false;
        state.patchErrors = [];
    },
    [CHANGE_INVOICE_DELIVERY_FAILURE]: (state, action) => {
        state.patching = false;
        state.patchErrors = action.payload.errors;
    },
    [USER_ACTION_RESET]: (state) => {
        state.userActionStatus = CommunicationState.INITIAL;
    },
    [USER_ACTION_STARTED]: (state) => {
        state.userActionStatus = CommunicationState.LOADING;
        state.userActionErrors = [];
    },
    [USER_ACTION_SUCCESS]: (state) => {
        state.userActionStatus = CommunicationState.SUCCESS;
    },
    [USER_ACTION_FAILURE]: (state, action) => {
        state.userActionStatus = CommunicationState.FAILURE;
        state.userActionErrors = action.payload.errors;
    },
});
