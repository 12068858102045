import { RootStore } from '../rootStore';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';
import RestClient from '../../http/RestClient';
import { extractErrorTags, WeWashApiError } from '../../http/errors';
import { ThunkDispatch } from 'redux-thunk';
import { GroupedUserInvoicesInfoResponse } from './groupedUserInvoicesReducer';
import { parseContentDispositionForFilename } from '../../util/downloadFileAsBlob';

export const FETCH_GROUPED_USER_INVOICES_INFO =
    'FETCH_GROUPED_USER_INVOICES_INFO';
export const FETCH_GROUPED_USER_INVOICES_INFO_SUCCESS =
    'FETCH_GROUPED_USER_INVOICES_INFO_SUCCESS';
export const FETCH_GROUPED_USER_INVOICES_INFO_FAILURE =
    'FETCH_GROUPED_USER_INVOICES_INFO_FAILURE';

export const RESET_GROUPED_USER_INVOICES = 'RESET_GROUPED_USER_INVOICES';
export const DOWNLOAD_GROUPED_USER_INVOICES_PDF =
    'DOWNLOAD_GROUPED_USER_INVOICES_PDF_INFO';
export const DOWNLOAD_GROUPED_USER_INVOICES_PDF_FAILURE =
    'DOWNLOAD_GROUPED_USER_INVOICES_PDF_INFO_FAILURE';
export const DOWNLOAD_GROUPED_USER_INVOICES_PDF_SUCCESS =
    'DOWNLOAD_GROUPED_USER_INVOICES_PDF_INFO_SUCCESS';
export const DOWNLOAD_GROUPED_USER_INVOICES_CSV =
    'DOWNLOAD_GROUPED_USER_INVOICES_CSV_INFO';
export const DOWNLOAD_GROUPED_USER_INVOICES_CSV_FAILURE =
    'DOWNLOAD_GROUPED_USER_INVOICES_CSV_INFO_FAILURE';
export const DOWNLOAD_GROUPED_USER_INVOICES_CSV_SUCCESS =
    'DOWNLOAD_GROUPED_USER_INVOICES_CSV_INFO_SUCCESS';
export const DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF =
    'DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF_INFO';
export const DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF_FAILURE =
    'DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF_INFO_FAILURE';
export const DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF_SUCCESS =
    'DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF_INFO_SUCCESS';

export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';

export interface GroupedUserInvoiceRequestDto {
    month: number;
    year: number;
    country_code: string;
}

export interface GroupedSelectedInvoiceRequestDto {
    invoice_numbers: string[];
}

export function resetDownloadInvoiceStore() {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: RESET_GROUPED_USER_INVOICES });
    };
}

export function getCountries() {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        try {
            const { data } = await RestClient.get('v1/countries');
            dispatch({
                type: FETCH_COUNTRIES_SUCCESS,
                payload: { countries: data },
            });
        } catch (error) {
            dispatch({
                type: FETCH_COUNTRIES_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}

export function getGroupedUserInvoicesInfoFor(
    dto: GroupedUserInvoiceRequestDto
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({
            type: FETCH_GROUPED_USER_INVOICES_INFO,
            payload: { data: dto },
        });
        try {
            const { data }: AxiosResponse<GroupedUserInvoicesInfoResponse> =
                await RestClient.post(
                    'v1/callcenter/grouped_invoices/info',
                    dto
                );
            dispatch({
                type: FETCH_GROUPED_USER_INVOICES_INFO_SUCCESS,
                payload: { data },
            });
        } catch (error) {
            dispatch({
                type: FETCH_GROUPED_USER_INVOICES_INFO_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}

export function downloadGroupedUserInvoicesPdf(
    dto: GroupedUserInvoiceRequestDto,
    downloadFile: (filename: string, blob: Blob) => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: DOWNLOAD_GROUPED_USER_INVOICES_PDF });
        try {
            const downloadZipResponse: AxiosResponse = await RestClient.post(
                'v1/callcenter/grouped_invoices/download',
                dto,
                { responseType: 'blob' }
            );
            dispatch({ type: DOWNLOAD_GROUPED_USER_INVOICES_PDF_SUCCESS });
            const filename =
                parseContentDispositionForFilename(
                    downloadZipResponse.headers['content-disposition']
                ) || getFileName(dto, 'zip');
            downloadFile(filename, downloadZipResponse.data);
        } catch (error) {
            dispatch({
                type: DOWNLOAD_GROUPED_USER_INVOICES_PDF_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}

export function downloadGroupedUserInvoicesCsv(
    dto: GroupedUserInvoiceRequestDto,
    downloadFile: (filename: string, blob: Blob) => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: DOWNLOAD_GROUPED_USER_INVOICES_CSV });
        try {
            const downloadCsvResponse: AxiosResponse = await RestClient.post(
                'v1/callcenter/grouped_invoices/csv/download',
                dto,
                { responseType: 'blob' }
            );
            dispatch({ type: DOWNLOAD_GROUPED_USER_INVOICES_CSV_SUCCESS });
            const filename =
                parseContentDispositionForFilename(
                    downloadCsvResponse.headers['content-disposition']
                ) || getFileName(dto, 'csv');
            downloadFile(filename, downloadCsvResponse.data);
        } catch (error) {
            dispatch({
                type: DOWNLOAD_GROUPED_USER_INVOICES_CSV_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}

export function downloadGroupedSelectedUserInvoicesPdf(
    dto: GroupedSelectedInvoiceRequestDto,
    downloadFile: (filename: string, blob: Blob) => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF });
        try {
            const downloadZipResponse: AxiosResponse = await RestClient.post(
                'v1/callcenter/grouped_invoices/selected_download',
                dto,
                { responseType: 'blob' }
            );
            dispatch({ type: DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF_SUCCESS });
            const filename = 'user-invoices.zip';
            downloadFile(filename, downloadZipResponse.data);
        } catch (error) {
            dispatch({
                type: DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}

function getFileName(dto: GroupedUserInvoiceRequestDto, ending: string) {
    const year = dto.year;
    const month = `00${dto.month}`.substr(-2);
    const countryCode = dto.country_code;

    return `${year}-${month}-${countryCode}-user-invoices.${ending}`;
}
