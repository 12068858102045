import React from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Form } from 'react-bootstrap';
import {
    LaundryRoomCardDto,
    LaundryRoomCardWriteDto,
} from '../../../store/manage-laundry-room/manageLaundryRoomActions';

interface Props {
    cardData: LaundryRoomCardDto;
    onPatchLaundryRoomCard: (
        laundryRoomId: number,
        cardId: number,
        card: LaundryRoomCardWriteDto
    ) => void;
    configuredLanguages: string[];
    onDelete: (laundryRoomId: number, cardId: number) => void;
}

export interface TransposedCardInfo {
    laundry_room_id: string;
    internal_link: string;

    [key: string]: string;
}

export enum CARD_ITEM {
    TITLE = 'title',
    DESCRIPTION = 'description',
    BUTTON = 'button',
    LINK = 'link',
}

export function LaundryRoomCardForm(props: Readonly<Props>) {
    const initialValues: TransposedCardInfo = flattenCardInfo(
        props.cardData,
        props.configuredLanguages
    );

    function handleCardEditSubmit(updatedInfo: TransposedCardInfo) {
        if (!props.cardData) {
            return;
        }
        const writeDto: LaundryRoomCardWriteDto = transposeToCardInfo(
            updatedInfo,
            props.configuredLanguages,
            props.cardData
        );
        props.onPatchLaundryRoomCard(
            props.cardData.laundry_room_id,
            props.cardData.card_id,
            writeDto
        );
    }

    function handleCardDelete(laundryRoomId: number, cardId: number) {
        props.onDelete(laundryRoomId, cardId);
    }

    function renderButtons(laundryRoomId: number, cardId: number) {
        return (
            <div style={{ marginBottom: 10 }}>
                <Button type="submit">Karte konfigurieren</Button>
                <Button
                    variant="outline-secondary"
                    className={'ml-2'}
                    onClick={() => handleCardDelete(laundryRoomId, cardId)}
                >
                    Karte entfernen
                </Button>
            </div>
        );
    }

    return (
        <Formik
            onSubmit={handleCardEditSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
        >
            {(formProps: FormikProps<TransposedCardInfo>) => {
                return (
                    <Form noValidate onSubmit={formProps.handleSubmit}>
                        {renderInternalLink(formProps)}
                        {renderButtons(
                            parseInt(formProps.values.laundry_room_id),
                            props.cardData.card_id
                        )}
                        {props.configuredLanguages.map((language) =>
                            renderForLanguage(language, formProps)
                        )}
                        {renderButtons(
                            parseInt(formProps.values.laundry_room_id),
                            props.cardData.card_id
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
}

export function renderInternalLink(formProps: FormikProps<TransposedCardInfo>) {
    return (
        <div>
            <p>Enthält die Karte nur Links zu WeWash-internen Seiten?</p>
            <Form.Group controlId={'internal_link'}>
                <Form.Check
                    type={'checkbox'}
                    value={''}
                    checked={!!formProps.values.internal_link}
                    onChange={formProps.handleChange}
                    label={'interner Link'}
                />
            </Form.Group>
        </div>
    );
}

export function renderForLanguage(
    language: string,
    formProps: FormikProps<TransposedCardInfo>
) {
    return (
        <div
            key={language}
            style={{ border: '1px solid grey', marginBottom: 15, padding: 5 }}
        >
            <strong>Sprache: {language} </strong>
            <div style={{ marginBlockStart: 10 }}>
                {renderItemForLanguage(CARD_ITEM.TITLE, language, formProps)}
                {renderItemForLanguage(
                    CARD_ITEM.DESCRIPTION,
                    language,
                    formProps
                )}
                {renderItemForLanguage(CARD_ITEM.BUTTON, language, formProps)}
                {renderItemForLanguage(CARD_ITEM.LINK, language, formProps)}
            </div>
        </div>
    );
}

export function renderItemForLanguage(
    item: CARD_ITEM,
    language: string,
    formProps: FormikProps<TransposedCardInfo>
) {
    const ITEM_TO_TEXT: { [key in CARD_ITEM]: string } = {
        title: 'Titel (25 Zeichen)',
        description:
            'Text (mit Button/Link: 65 Zeichen; ohne Button/Link: 100 Zeichen)',
        button: 'Button (10 Zeichen)',
        link: 'Link',
    };

    return (
        <Form.Group controlId={`${item}_${language}`}>
            <Form.Label>{ITEM_TO_TEXT[item]}</Form.Label>
            <Form.Control
                type={'text'}
                onChange={formProps.handleChange}
                value={formProps.values[`${item}_${language}`]}
            />
        </Form.Group>
    );
}

export function flattenCardInfo(
    cardInfo: LaundryRoomCardWriteDto,
    languages: string[]
): TransposedCardInfo {
    const result: TransposedCardInfo = {
        internal_link: cardInfo.internal_link ? 'true' : '',
        laundry_room_id: String(cardInfo.laundry_room_id),
    };
    languages.forEach((it) => {
        result[`${CARD_ITEM.TITLE}_${it}`] = (cardInfo.title || {})[it] || '';
        result[`${CARD_ITEM.DESCRIPTION}_${it}`] =
            (cardInfo.description || {})[it] || '';
        result[`${CARD_ITEM.BUTTON}_${it}`] =
            (cardInfo.button_text || {})[it] || '';
        result[`${CARD_ITEM.LINK}_${it}`] = (cardInfo.link || {})[it] || '';
    });
    return result;
}

export function transposeToCardInfo(
    flat: TransposedCardInfo,
    configuredLanguages: string[],
    existingCardData: LaundryRoomCardWriteDto
): LaundryRoomCardWriteDto {
    const result: LaundryRoomCardWriteDto = {
        ...existingCardData,
        title: {},
        description: {},
        button_text: {},
        link: {},
        internal_link: !!flat.internal_link,
    };

    configuredLanguages.forEach((lang) => {
        result.title[lang] = flat[`${CARD_ITEM.TITLE}_${lang}`];
        result.description[lang] = flat[`${CARD_ITEM.DESCRIPTION}_${lang}`];
        result.button_text[lang] = flat[`${CARD_ITEM.BUTTON}_${lang}`];
        result.link[lang] = flat[`${CARD_ITEM.LINK}_${lang}`];
    });

    return result;
}
