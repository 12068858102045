import { createReducer } from 'redux-starter-kit';
import {
    DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF,
    DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF_FAILURE,
    DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF_SUCCESS,
    DOWNLOAD_GROUPED_USER_INVOICES_CSV,
    DOWNLOAD_GROUPED_USER_INVOICES_CSV_FAILURE,
    DOWNLOAD_GROUPED_USER_INVOICES_CSV_SUCCESS,
    DOWNLOAD_GROUPED_USER_INVOICES_PDF,
    DOWNLOAD_GROUPED_USER_INVOICES_PDF_FAILURE,
    DOWNLOAD_GROUPED_USER_INVOICES_PDF_SUCCESS,
    FETCH_COUNTRIES_FAILURE,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_GROUPED_USER_INVOICES_INFO,
    FETCH_GROUPED_USER_INVOICES_INFO_FAILURE,
    FETCH_GROUPED_USER_INVOICES_INFO_SUCCESS,
    RESET_GROUPED_USER_INVOICES,
} from './groupedUserInvoicesAction';
import { WeWashApiErrorTag } from '../../http/errors';
import { CommunicationState } from '../communicationState';
import { CountryDto } from '../country';

export interface GroupedUserInvoicesInfoResponse {
    count_by_status: { [key: string]: number };
}

export interface GroupedUserInvoicesStore {
    loading: boolean;
    groupedInvoicesPdfDownload: CommunicationState;
    groupedInvoicesCsvDownload: CommunicationState;
    groupedSelectedInvoicesPdfDownload: CommunicationState;
    groupedUserInvoicesInfoResponse: GroupedUserInvoicesInfoResponse | null;
    countries: CountryDto[];
    errors: WeWashApiErrorTag[];
}

const initialStore: GroupedUserInvoicesStore = {
    loading: false,
    groupedInvoicesPdfDownload: CommunicationState.INITIAL,
    groupedInvoicesCsvDownload: CommunicationState.INITIAL,
    groupedSelectedInvoicesPdfDownload: CommunicationState.INITIAL,
    groupedUserInvoicesInfoResponse: null,
    countries: [],
    errors: [],
};

export const groupedUserInvoicesReducer = createReducer(initialStore, {
    [RESET_GROUPED_USER_INVOICES]: (state: GroupedUserInvoicesStore) => {
        state.loading = true;
        state.errors = [];
        state.groupedUserInvoicesInfoResponse = null;
        state.groupedInvoicesPdfDownload = CommunicationState.INITIAL;
        state.groupedInvoicesCsvDownload = CommunicationState.INITIAL;
        state.groupedSelectedInvoicesPdfDownload = CommunicationState.INITIAL;
    },
    [FETCH_GROUPED_USER_INVOICES_INFO]: (state: GroupedUserInvoicesStore) => {
        state.loading = true;
        state.errors = [];
    },
    [FETCH_GROUPED_USER_INVOICES_INFO_SUCCESS]: (
        state: GroupedUserInvoicesStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
        state.groupedUserInvoicesInfoResponse = action.payload.data;
    },
    [FETCH_GROUPED_USER_INVOICES_INFO_FAILURE]: (
        state: GroupedUserInvoicesStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [DOWNLOAD_GROUPED_USER_INVOICES_PDF]: (state: GroupedUserInvoicesStore) => {
        state.loading = true;
        state.errors = [];
        state.groupedInvoicesPdfDownload = CommunicationState.LOADING;
    },
    [DOWNLOAD_GROUPED_USER_INVOICES_PDF_SUCCESS]: (
        state: GroupedUserInvoicesStore
    ) => {
        state.loading = false;
        state.errors = [];
        state.groupedInvoicesPdfDownload = CommunicationState.SUCCESS;
    },
    [DOWNLOAD_GROUPED_USER_INVOICES_PDF_FAILURE]: (
        state: GroupedUserInvoicesStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
        state.groupedInvoicesPdfDownload = CommunicationState.FAILURE;
    },
    [DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF]: (
        state: GroupedUserInvoicesStore
    ) => {
        state.loading = true;
        state.errors = [];
        state.groupedSelectedInvoicesPdfDownload = CommunicationState.LOADING;
    },
    [DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF_SUCCESS]: (
        state: GroupedUserInvoicesStore
    ) => {
        state.loading = false;
        state.errors = [];
        state.groupedSelectedInvoicesPdfDownload = CommunicationState.SUCCESS;
    },
    [DOWNLOAD_GROUPED_SELECTED_INVOICES_PDF_FAILURE]: (
        state: GroupedUserInvoicesStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
        state.groupedSelectedInvoicesPdfDownload = CommunicationState.FAILURE;
    },
    [DOWNLOAD_GROUPED_USER_INVOICES_CSV]: (state: GroupedUserInvoicesStore) => {
        state.loading = true;
        state.errors = [];
        state.groupedInvoicesCsvDownload = CommunicationState.LOADING;
    },
    [DOWNLOAD_GROUPED_USER_INVOICES_CSV_SUCCESS]: (
        state: GroupedUserInvoicesStore
    ) => {
        state.loading = false;
        state.errors = [];
        state.groupedInvoicesCsvDownload = CommunicationState.SUCCESS;
    },
    [DOWNLOAD_GROUPED_USER_INVOICES_CSV_FAILURE]: (
        state: GroupedUserInvoicesStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
        state.groupedInvoicesCsvDownload = CommunicationState.FAILURE;
    },
    [FETCH_COUNTRIES_SUCCESS]: (state: GroupedUserInvoicesStore, action) => {
        state.countries = action.payload.countries;
    },
    [FETCH_COUNTRIES_FAILURE]: (state: GroupedUserInvoicesStore, action) => {
        state.errors = action.payload.errors;
    },
});
